<template>
  <div class="region-search">
    <div class="filter-tab">
      <el-form inline ref="filterForm" :model="filterForm" :rules="rules">
        <el-form-item label="时间" prop="time">
          <el-date-picker
            :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 1, 1,23, 59, 59),]"
            size="small"
            v-model="filterForm.time"
            type="daterange"
            unlink-panels
            range-separator="To"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="省" v-if="provinceList.length>0" prop="area">
          <el-select
            size="small"
            v-model="filterForm.province"
            placeholder="选择省"
            clearable
            @change="getCity"
          >
            <el-option
              v-for="item in provinceList"
              :key="item[0]" :label="item[1]" :value="item[0]"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="cityOptions.length>0" label="地区" prop="area">
          <el-select
            size="small"
            v-model="filterForm.city"
            placeholder="选择地区"
            clearable
            @change="getCounty"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item[0]" :label="item[1]" :value="item[0]"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="countyOptions.length>0" label="选择区县" prop="area" >
          <el-select
            v-model="filterForm.county"
            placeholder="选择地区"
            clearable
            @change="selectArea"
            size="small"
          >
            <el-option
              v-for="item in countyOptions"
              :key="item[0]" :label="item[1]" :value="item[0]"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="flex:0 0 10vw;">
          <el-button class="btn-search" type="primary" size="small" @click="dosearch()"
            >查询</el-button
          >
          <el-button class="btn-reset" type="" size="small" @click="reset()"> 重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tool-bar">
      <div style="width:fit-content;font-size:1.5vh;">
        <span>总计:{{total}}&nbsp;合格:{{qualified}}&nbsp;不合格:{{unqualified}}</span>
      </div>
      &nbsp;&nbsp;
      <el-button size="small" type="success"  @click ="saveToLocal()" :disabled="true"
        >导出</el-button>
    </div>
    <div class="table-wrapper table-area">
    <el-table :size="pageSize" :data="dataView" 
        
        height="69vh"
    fit
    :row-style="redRow"
    @row-contextmenu="showDetails"
    :header-row-style="headrowstyle"
    :header-cell-style="headercellstyle"
     style="--el-table-border-color:none;--el-table-row-hover-bg-color:#0d6c9f"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="No" type="index" :index="tableIndex"/>
      <el-table-column prop="check_time" label="日期" :formatter="dateTranser"/>
      <el-table-column prop="check_time" label="时间" :formatter="timeTranser" show-overflow-tooltip/>
      <el-table-column prop="send_company"  label="被检单位" />
      <el-table-column prop="company_name"  label="检测单位" />
      <el-table-column prop="sample" label="样品" />
      <el-table-column prop="check_item" label="检测项目" />
      <el-table-column prop="ref_info" label="标准"/>
      <el-table-column prop="check_value" label="检测值" width="60"/>
      <el-table-column prop="check_result" label="结果" :formatter="resultTranser" />
      
    </el-table>
    <el-pagination
         class="el-pagination" background layout="->,prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
  <el-dialog
    v-if="detailData !== undefined"
    ref="detailDialog"
    v-model="detailDialogVisible"
    :append-to-body="true"
    width="1150px"
    center
    top="3vh"
  >
    <data-detail id="dataDetail" :data="detailData" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="printDetails()">打印</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { getAreaData } from "network/checkdata.js";
import { getCityList, getCountyList,getAreaInfo } from "network/area.js"; 
import {download} from "utils/xlsxUtils.js" ;
import {
  Download
} from "@element-plus/icons-vue";
import DataDetail from "./DataDetail.vue";
export default {
  components: {
    Download,
    DataDetail
  },
  data() {
    return {
      headrowstyle:{ "font-size": "1.5vh",
        height: "2vh",
        "line-height": "2vh",
        color: "black",
        border: "1px solid black",
        'background-color':"#165191"},
        headercellstyle:{'background-color':"#165191"},
      provinceOptions:[],
      cityOptions: [],
      countyOptions: [],
      filterForm: {
        time: [],
        province:"",
        city: "",
        county: "",
        area:"",
      },
      searchLoading:false,
      rules:{
        time:[
          {required:true,message:'时间不能为空',trigger:'change'}
        ],
        area:[
          {required:true,message:'区域不能为空',trigger:'change'}
        ]
      },
      dataView: []
      ,qualified:0,unqualified:0,
      pageNo: 1,
      pageSize:20,
      total:0,
      detailDialogVisible:false,
      detailData:{},
      buttonActive:false
    };
  },
   computed:{
    tableIndex(){
      return (this.pageNo-1)*this.pageSize+1
    },
    provinceList() {
      return this.$store.getters['areas/provinces']
    },
    cityList() {
      return this.$store.getters['areas/cities']
    },
    areaList() {
      return this.$store.getters['areas/areas']
    },
    userAuth() {
      return this.$store.getters.getUserAuth
    },
    userRole() {
      return this.$store.getters.getUserRole
    },
    // authArea(){
    //   return JSON.parse(window.atob(localStorage.getItem("currentLogin"))).scope
    // }
  },
  methods: {
    setDefaultDate(){
        let firstdayofmounth=new Date()
        firstdayofmounth.setDate(1)
          this.filterForm.time=[firstdayofmounth,new Date()]
    },
    selectArea(val){
      if(val&&val !== ''  )
      this.filterForm.area=val
      else if(this.filterForm.city!==""&&this.filterForm.city!==null)
      this.filterForm.area=this.filterForm.city
    },
   redRow:({row,rowIndex})=>{
      let style = {
        height: "3vh",
        fontSize: "1.5vh",
        'background-color':'#06237a',
        border:'none',
        color:'#f2f2f2'
        //'background-color':'transparent'
      }
      if(rowIndex %2===0){
        style['background-color'] = '#013380'
      }
      if (row["check_result"] == 2)
        style.color = "red"
      if (row["status"] === "2") {
        style.textDecoration = "line-through"
      }
      return style
    },
     dateTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleDateString();
    },
    timeTranser: (row, column) => {
      let result = row[column.property];
      return new Date(result).toLocaleTimeString();
    },
    resultTranser: function (row, column) {
      let result = row[column.property];
      switch (result) {
        case "1":
          return "合格";
        case "2":
          return "不合格";
        default:
          return "待定";
      }
    },
    loadingAuth(){
      if(this.userRole==='org')
        return
      let auths=this.userAuth
      for(let auth of auths)
      {
        if(auth.match(/\d{2}0000/))
          getAreaInfo(auth).then(
            res=>{
              this.provinceOptions.push(...res.data)
            },
            e=>{console.log(e.message)}
          )
        else if(auth.match(/\d{4}00/))
          getAreaInfo(auth).then(
            res=>{
              this.cityOptions.push(...res.data)
            },
            e=>{console.log(e.message)}
          )
        else if(auth.match(/\d{4}00/))
          getAreaInfo(auth).then(
            res=>{
              this.countyOptions.push(...res.data)
            },
            e=>{console.log(e.message)}
          )
      }
    },
    getCity(val) {
      this.filterForm.city=""
      this.filterForm.county=""
      this.filterForm.area=""
      if(val===''||val===undefined)
       return
      this.filterForm.area=val
      
      this.cityOptions=this.cityList.filter(e=>e[0].startsWith(val.substring(0,2)))
    },
    getCounty(val){
      this.filterForm.county=""
      this.filterForm.area=""
      if (val === '' || val === null)
      {if(this.filterForm.province!==""&&this.filterForm.province!==null)
      this.filterForm.area=this.filterForm.province
        return}
      this.filterForm.area=val
      this.countyOptions=this.areaList.filter(e=>e[0].startsWith(val.substring(0,4)))
    },
    reset(){
      this.$refs.filterForm.resetFields()
       this.filterForm.province=""
        this.filterForm.city= ""
        this.filterForm.county=""
    },
    dosearch() {
      let filter=this.$refs.filterForm
      filter.validate(valid=>{
        if(valid)
        {
          let areaId=this.filterForm.city
          if(this.filterForm.county!="")
            areaId=this.filterForm.county
          getAreaData(
            this.filterForm.time,
            areaId,
            this.pageNo,
            this.pageSize
          ).then(
            res=>{
              this.pageNo=res.data.pageNum
              this.total=res.data.total
              this.dataView=res.data.dataList
              this.buttonActive=true;
              if(res.data.qualified!==undefined)
              this.qualified=res.data.qualified
              if(res.data.unQualified!==undefined)
              this.unqualified=res.data.unQualified
            },
            err=>{
              switch(err.status)
              {
                case 403:
                  this.$message.warning(err.message)
              }
            }
          )
        }else{
          this.$message.warning("检查输入格式")
        }
      })
    },
    changePage: function (page) {
      this.pageNo=page
      this.dosearch();
    },
    showDetails: function (row, column, event) {
      event.preventDefault();
      this.detailData = row;
      this.detailDialogVisible = true;
    },
    printDetails() {
      let doc = document.querySelector("#dataDetail").outerHTML;
      if (doc == undefined) {
        this.$message.error("print fail");
        return;
      }
      let win,
        iframedoc,
        iframe = document.createElement("iframe"),
        f = document.body.appendChild(iframe);
      iframe.onload = () => {
        try{
          setTimeout(function () {
          win.focus();
          try {
            if (!win.document.execCommand("print", false, null)) {
              win.print();
            }
          } catch (e) {
            win.print();
          }
          win.close();
        }, 100);
        }catch(e)
        {
          console.log('error:'+e);
        }
        

        setTimeout(function () {
          document.body.removeChild(iframe);
        }, 1000);
      };
      win = f.contentWindow || f.contentDocument;
      iframedoc = f.contentDocument || f.contentWindow.document;
      iframedoc.open();
      iframedoc.write(doc);
      iframedoc.close();
    },
    saveToLocal(){
      if (this.datatable.length == 0) this.dosearch();
      try{download(this.datatable,"地区快检数据",'快检数据');}
      catch(e){
        console.log(e)
      }
      
    },
    prepare(){
      if(this.provinceList.length<=0||this.provinceList===null){
        if(this.cityList&&this.cityList.length>0)
          this.cityOptions=this.cityList
        else
          this.countyOptions=this.areaList
    }
      
    }
  },
  created() {
    this.prepare()
    this.setDefaultDate()
  },
};
</script>
<style lang="less" scoped>
.region-search {
   width: 90vw;
  height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tool-bar {
    display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 5vw);
  height: 4vh;
  .el-button {
    height: 3vh;
    font-size: 1.5vh;
  }
  }
  .filter-tab {
    width: calc(100% - 5vw);
  flex: 0 0 8vh;
  border: 0.5vh solid var(--el-border-color-base);
  display: flex;
  align-items: center;
  justify-content: space-around;
  .el-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .el-button {
      height: 4vh;
      font-size: 1.5vh;
      width:4vw; 
    }
    .el-form-item {
      --font-size: 2vh;
      --el-form-label-font-size: var(--font-size);
      .el-input {
        height: 4vh;
        .el-input__inner {
          height: 4vh;
        }
      }
    }
  }
  }
  .table-wrapper {
   width: calc(100% - 4.5vw);
  height: 72vh;
  flex: 0 0 72vh;
  border: 0.5vh solid var(--el-border-color-base);
  .el-table {
    width: 100%;
  
  }
  .el-pagination {
    height: 3vh;
  }
  }
}

//表格样式
.table-area /deep/ .el-table__body-wrapper {
  background-color:#06237a ;
}

.filter-tab /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  // line-height: 3vh;
}
//输入框样式
.filter-tab /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
.filter-tab /deep/ input {
  color: #f2f2f2;
}
//按钮样式
.btn-search {
  width: 6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  border-radius: 5px;
}
.btn-reset {
  width:6.5vh;
  height: 3vh;
  line-height: 3vh;
  background-color: transparent;
  border: 1px solid #1763FF;
  color: white;
  margin-left: 0;
  border-radius: 5px;
}.el-pagination {
  padding: 1vh;
}
</style>